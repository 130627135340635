
<template>
  <div class="pg-dashboard pg-page">
    <div class="pg-header pt-5 pb-3">
      <img :src="app.koperasi_logo" alt="">
      <!-- <h1>Mobile {{app.koperasi_name}}</h1> -->
      <h6>Transaksi Terima</h6>
      <div class="pg-header-nav-btn">
        <router-link to="/profile">
          <i class="fas fa-cog"></i>
        </router-link>
      </div>
    </div>
    <div class="pg-content">
      <div class="d-flex flex-column align-items-center justify-content-center">
      <!-- <b-img :src="require('../assets/images/qrcode.png')" alt="" fluid/> -->
      <vue-qrcode :value="user.cif_no" :options="{ width: 300 }"></vue-qrcode>
      <span>No Rek : {{user.cif_no}}</span>
      <span>A.n {{user.nama}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapGetters,
  mapActions
} from "vuex";
import VueQrcode from '@chenfengyuan/vue-qrcode';
import {baseUrl,settings} from '../config'
export default {
  components: {
    VueQrcode
  },
  computed: {
    ...mapGetters(["user"])
  },
  data(){
    return {
      app :settings,
      hitlog : {
        cif_no: null,
        timestamp : null,
        menu_akses : null
      }
    }
  },
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    getHitlog(){
      this.hitlog.loading = true
      let url = `${baseUrl}/histori_login`
      let payloadData = {
        cif_no : this.user.cif_no,
        timestamp : this.user.timestamp,
        menu_akses : 'TransaksiTerima'
      }

      let payload = new FormData()
      for(let key in payloadData){
        payload.append(key,payloadData[key])
      }
      axios
      .post(url,payload)
      .then((res)=>{
        this.hitlog.loading = false
        this.hitlog = res.data
      })
      .catch((res)=>{
        this.hitlog.loading = false
        this.notif('Error',res.message,'danger')
      })
    }
  },
  mounted(){
    this.getHitlog()
  }
}
</script>